import React, { useRef } from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  
  const form = useRef();
  
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        form.current,
        process.env.REACT_APP_API
      )
      .then((res) => {
        alert("Message Sent");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    e.target.reset();
  }


  return (
    <div className="max-w-screen-sm mx-auto p-5 bg-white" id="contact">
      <div className="text-left mb-8 px-3 sm:pt-5">
        <h3 className="text-3xl mt-4 sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-900">
          Get In <span className="text-teal-600">Touch</span>
        </h3>
        <p className="text-gray-600 text-lg py-3">
          Please fill out the form below or
          <span
            onClick={() =>
              (window.location = "mailto:info@nuovagen.com.au")
            }
            className="text-teal-600 underline underline-offset-2 cursor-pointer px-1"
          >
            click here
          </span>
          to send us an email.
        </p>
      </div>

      <form className="w-full px-3 mx-auto" ref={form} onSubmit={sendEmail}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              htmlFor="grid-first-name"
            >
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal-500"
              id="grid-last-name"
              type="text"
              name="user_first"
              placeholder="First Name"
              required
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-teal-500"
              id="grid-last-name"
              type="text"
              name="user_last"
              placeholder="Last Name"
              required
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-4">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              htmlFor="grid-password"
            >
              Email Address
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-teal-500"
              id="grid-email"
              type="email"
              name="user_email"
              placeholder="name@example.com"
              required
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              htmlFor="grid-password"
            >
              Your Message
            </label>
            <textarea
              rows="10"
              name="user_message"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-teal-500"
              required
            ></textarea>
          </div>
          <div className="flex mt-1 justify-between w-full px-3">
            <button
              className="shadow bg-teal-600 hover:bg-teal-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
              type="submit"
            >
              Send Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
