import React from "react";
import { MdCenterFocusStrong, MdGpsFixed } from "react-icons/md";
import { RiCheckboxCircleLine } from "react-icons/ri";

const lists = [
  {
    title: "Growth & Adaptability",
    desc: "To continuously evolve, embracing change as an ally, and harnessing new possibilities.",
    Icon: MdCenterFocusStrong,
  },
  {
    title: "Value Creation",
    desc: "To generate unparalleled value for our clients, stakeholders, and the communities we serve.",
    Icon: RiCheckboxCircleLine,
  },
  {
    title: "Sustainable Practices",
    desc: "To integrate eco-consciousness into our DNA, ensuring a better world for future generations.",
    Icon: MdGpsFixed,
  },
];

const ListItem = ({ title, desc, Icon }) => (
  <div
    className="border flex flex-col px-6 sm:px-8 justify-center items-start bg-white cursor-pointer 
      shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl hover:bg-teal-500 hover:text-white h-[270px]"
  >
    <div className="p-4 inline-block bg-gray-200 rounded-full">
      <Icon className="text-teal-600" size="30px" />
    </div>
    <h4 className="my-4 font-extrabold text-2xl tracking-wide">{title}</h4>
    <p className="text-sm">{desc}</p>
  </div>
);

export default function HomeThree() {
  return (
    <section className="w-full py-4 flex flex-col justify-center items-start min-h-[420px] h-auto">
      <div className="sm:w-[90vw] w-[95vw] mx-auto pt-4">
        <h2 className="leading-none font-black text-3xl pl-3">Our Mission</h2>
      </div>
      <div
        className="sm:w-[90vw] w-[92vw] h-auto mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 items-center 
        justify-center gap-y-5 sm:gap-y-20 gap-x-14 my-10"
      >
        {lists.map((list, index) => (
          <ListItem key={index} {...list} />
        ))}
      </div>
    </section>
  );
}
