import { AiOutlineClose} from "react-icons/ai";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";

export default function Diaglog({ open }) {
  
  return (
    <div
      className="fixed z-10 overflow-y-auto top-[50%] w-full left-[50%] 
    translate-x-[-50%] translate-y-[-50%] "
    >
      <div
        className="flex items-center justify-center min-height-100vh 
      pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg 
          text-left overflow-hidden shadow-xl transform transition-all 
          sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-teal-500"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex items-end justify-end px-4 py-3">
            <button
              onClick={open}
              type="button"
              className="
               outline-none text-lg"
            >
              <AiOutlineClose size={"30px"} />
            </button>
          </div>
          <div
            className="flex flex-col items-center w-full 
          justify-center my-auto sm:h-[410px] border-t h-auto px-4 py-4"
          >
            <div className="flex flex-col gap-5">
              <h1 className="w-auto font-semibold text-3xl sm:text-5xl text-left">
                Coming soon.
              </h1>
              <p className="mb-4">
                The world of NuovaGen is ever-evolving, and we are thrilled to
                announce that some incredible new additions are on the horizon.
                Our team has been working tirelessly to bring you innovative
                services and experiences that promise to redefine excellence.
              </p>
            </div>
            <div className="flex flex-col items-start justify-center">
              <p className=" mb-8">
                We can't reveal all our secrets just yet, but we promise that
                what's coming is worth the wait.
              </p>
              <div className="flex">
                <button
                  className="text-blue-600 bg-white rounded-full flex font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <AiFillFacebook size={"25px"} />
                </button>
                <button
                  className="bg-gradient-to-r from-[#f9ce34] to-[#ee2a7b]
                   text-gray-900 flex shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <AiOutlineInstagram className="fill-[#fff]" size={"25px"} />
                </button>
                <button
                  onClick={() =>
                    (window.location = "mailto:info@nuovagen.com.au")
                  }
                  className="text-gray-200 bg-white flex font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <AiOutlineMail className="fill-[#c4302b]" size={"25px"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
