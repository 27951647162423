import React from "react";
import { BsTruck } from "react-icons/bs";
import { BiSolidBusSchool } from "react-icons/bi";
import { TfiInfoAlt } from "react-icons/tfi";
import {ReactComponent as Career} from "../../Img/career.svg"
import { SiCodechef } from "react-icons/si";

export default function HomeSeven() {
  return (
    <section
      className="py-20 bg-[#f4f4f4] sm:min-h-[80vh] h-auto flex items-center border-b-4 px-3" 
      id="career"
    >
      <div className="container items-center max-w-6xl px-4 px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
        <div className="flex flex-wrap items-center -mx-3">
          <div className="order-1 w-full px-3 lg:w-1/2 lg:order-0">
            <div className="w-full lg:max-w-md">
              <h2 className="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">
                Thank you for choosing us for your career.
              </h2>
              <p className="mb-4 font-medium tracking-tight text-gray-600 xl:mb-6">
                Please send your resume to
                <span
                  onClick={() =>
                    (window.location = "mailto:careers@nuovagen.com.au")
                  }
                  className="underline underline-offset-4 text-teal-600 cursor-pointer"
                >
                  {" "}
                  careers@nuovagen.com.au.{" "}
                </span>
                Our recruitment team would love to hear about your experience
                and qualifications. We are looking staff for following roles.
              </p>
              <ul>
                <li className="flex items-center py-2 space-x-4 xl:py-3">
                  <BsTruck className="text-teal-500" size={"30px"} />
                  <span className="font-medium text-gray-500">
                    Truck driver
                  </span>
                </li>
                <li className="flex items-center py-2 space-x-4 xl:py-3">
                  <BiSolidBusSchool className="text-teal-500" size={"30px"} />
                  <span className="font-medium text-gray-500">
                    Driving instructor
                  </span>
                </li>
                <li className="flex items-center py-2 space-x-4 xl:py-3">
                  <TfiInfoAlt className="text-teal-500" size={"30px"} />
                  <span className="font-medium text-gray-500">
                    Migration consultant
                  </span>
                </li>
                <li className="flex items-center py-2 space-x-4 xl:py-3">
                  <SiCodechef className="text-teal-500" size={"30px"} />
                  <span className="font-medium text-gray-500">
                    Contract Chef
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
            <Career />
          </div>
        </div>
      </div>
    </section>
  );
}
