import React from 'react'
import HomeOne from './HomeOne'
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import HomeFour from "./HomeFour";
import HomeFive from "./HomeFive";
import HomeSix from "./HomeSix";
import HomeSeven from "./HomeSeven"

export default function Home() {
  return (
    <div>
      <HomeOne />
      <HomeTwo/>
      <HomeThree />
      <HomeFour/>
      <HomeFive />
      <HomeSix />
      <HomeSeven />
    </div>
  )
}
