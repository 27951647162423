import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation } from "swiper/modules";


export default function HomeFive() {
  
  const lists = [
    {
      name: "Sulav Subedi",
      desc: "As a nervous first-time driver, Anil Dai gave me the confidence I needed. Their patient instructors and comprehensive on-road training were outstanding. I passed my test on the first try, all thanks to their expert guidance.",
    },
    {
      name: "Sujan KC",
      desc: "Anil dish has been so great and it was great to have him as our event chef. He has proven what a real taste of food taste like. Really admire his cooking skills and thanks for making our moments happy Anil.",
    },
    {
      name: "Alex Scott",
      desc: "Working with NuovaGen Logistic has been great as now we really don't have to care about delivery of our items anymore. More specially Anil has solved our problem related to our delivery.",
    },    
  ];

  return (
    <section className="w-full min-h-[450px] bg-[#f4f4f4] px-4 py-6">
      <header className="flex justify-center items-center mt-4 py-3">
        <h2 className="text-3xl sm:text-6xl font-bold mb-4 text-teal-600">
          Testimonials
        </h2>
      </header>
      <div className="sm:w-[700px] mx-auto min-h-[280px] h-auto flex justify-center items-center">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          navigation
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay, EffectFade, Navigation]}
          className="mySwiper"
          style={{ "--swiper-navigation-color": "teal" }}
        >
          {lists.map((list, index) => {
            const { name, desc } = list;
            return (
              <SwiperSlide key={index}>
                <div className="p-4 w-full bg-[#fff] rounded">
                  <div className="flex flex-col justify-start items-start p-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block w-5 h-5 text-gray-400 mb-4"
                      viewBox="0 0 975.036 975.036"
                    >
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <p className="leading-relaxed px-2">{desc}</p>
                  </div>

                  <div className="flex justify-between items-start rounded">
                    <span className="flex-grow flex flex-col pl-4">
                      <span className="title-font font-medium text-gray-900">
                        {name}
                      </span>
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}


