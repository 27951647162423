import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";

export default function HomeOne() {
  const lists = [
    {
      title: "NuovaGen Hospitality ",
      desc: "NuovaGen Hospitality provides training, support to the master chef of tomorrow. Here at NuovaGen we aim to make your hospitality career to next level. We also provide services related to private catering as well as private chef for your happy moments.",
      url: "https://cdn.pixabay.com/photo/2017/08/07/09/24/people-2601872_1280.jpg",
      path: "",
    },
    {
      title: "NuovaGen Logistic",
      desc: "NuovaGen Logistics redefines the realm of delivery and supply chain management. Our global network, cutting-edge technology, and commitment to eco-friendly practices ensure your cargo reaches its destination with unmatched efficiency. We offer comprehensive logistics solutions tailored to the unique needs of your business.",
      url: "https://cdn.pixabay.com/photo/2014/08/02/11/40/high-bay-408222_1280.jpg",
      path: "",
    },
    {
      title: "NuovaGen Driving School",
      desc: "At NuovaGen Driving School, we are not just teaching you to drive, we're preparing you for a lifetime of safe, confident, and responsible driving. Whether you're a beginner or looking to refresh your skills, our expert instructors are dedicated to ensuring you thrive behind the wheel.",
      url: "https://images.unsplash.com/photo-1611508106567-6218ae6c5f6a?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      path: "",
    },
    {
      title: "Nuovagen Skilled Migration",
      desc: "NuovaGen Skilled Migration Consultancy is your partner in unlocking international opportunities. With our expertise in global migration policies and a personalized approach, we pave the way for your professional aspirations abroad. Our team ensures a seamless transition, from visa processing to settlement services.",
      url: "https://cdn.pixabay.com/photo/2018/02/04/08/36/migration-3129299_1280.jpg",
      path: "",
    },
  ];
  return (
    <div className="w-full bg-black min-h-[80vh] h-auto " id="home">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay, EffectFade]}
        className="mySwiper"
      >
        {lists.map((list, index) => {
          const { title, desc, url } = list;
          return (
            <SwiperSlide key={index}>
              <div
                className="w-full  cursor-pointer relative sm:min-h-[88vh] h-auto min-h-[88vh]
                flex items-center justify-center"
              >
                <div
                  className="absolute top-0 w-full h-full bg-center bg-cover opcaity-75 bg-black"
                  style={{
                    backgroundImage: `url(${url})`,
                  }}
                >
                  <span className="w-full h-full absolute left-0 opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto pt-10">
                  <div className="w-full justify-center mx-auto flex flex-col">
                    <div className="px-4 mx-auto text-center">
                      <div className="sm:pr-12 flex flex-col gap-2 items-start">
                        <h1 className="text-white w-auto md:w-[50vw] font-semibold text-3xl sm:text-5xl text-left">
                          {title}
                        </h1>
                        <p className="sm:text-2xl tracking-wider mt-4 md:w-[50vw] w-auto text-base text-gray-200 sm:text-2xl text-left">
                          {desc}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
