import React from "react";

export default function HomeTwo() {
  return (
    <section className="flex items-center bg-stone-100 min-h-[80vh] h-auto font-poppins dark:bg-gray-800" id="about">
      <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
        <div className="px-4 mb-10 flex flex-col items-start md:text-center md:mb-20"></div>
        <div className="flex flex-wrap items-center">
          <div className="w-full px-4 mb-10 md:w-1/2 lg:mb-0">
            <h2 className="font-semibold animate-bounce text-base py-1">
              About Us
            </h2>
            <h2 className="mb-4 text-2xl font-bold text-gray-700 dark:text-gray-300">
              Our Genesis
            </h2>
            <p className="mb-4 text-base leading-7 text-gray-600 dark:text-gray-400">
              In a world rapidly evolving, the need for a company that
              seamlessly blends age-old values with cutting-edge innovation was
              paramount. Thus, NuovaGen was born. Rooted in dedication and
              excellence, our name embodies our philosophy: ushering in a new
              era across industries. From our modest beginnings, we've grown
              multifold, diversifying into various sectors while staying true to
              our core ideals.
            </p>
            <h2 className="mb-4 pt-5 text-2xl font-bold text-gray-700 dark:text-gray-300">
              Our Vision
            </h2>
            <p className="mb-4 text-base leading-7 text-gray-600 dark:text-gray-400">
              To be a beacon of excellence, setting new benchmarks across
              industries, and influencing positive change on a global scale.
            </p>
          </div>
          <div className="relative w-full px-4 mb-10 md:w-1/2 lg:mb-0">
            <img
              src="https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_1280.jpg"
              alt="NuovaGen Working Environment"
              className="relative z-40 object-cover w-full rounded-md md:h-96 h-44"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
