import React from "react";
import { Link } from "react-scroll";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from "react-icons/ai";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const now = new Date();
  const year = now.getFullYear();

  return (
    <footer className="bg-gray-900 text-white pt-12 pb-8 px-4">
      <div className="container overflow-hidden flex flex-col lg:flex-row justify-between mx-auto px-4">
        <h2 className="block mr-2 w-30 text-2xl font-serif font-bold">
          NuovaGen The
          <br /> New Beginning...
        </h2>

        <div className="w-3/4 block sm:flex text-sm mt-6 lg:mt-0">
          <ul className="list-none p-0 font-thin flex flex-col text-left w-full text-gray-500">
            <li className="uppercase font-bold text-white tracking-wide">
              Home Links
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={50}
                duration={1200}
                onClick={scrollToTop}
              >
                Home
              </Link>
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={50}
                duration={1200}
                to="about"
                onClick={scrollToTop}
              >
                About
              </Link>
            </li>
          </ul>

          <ul className="list-none p-0 font-thin flex flex-col text-left w-full text-gray-500">
            <li className="uppercase font-medium text-white tracking-wide">
              Other Links
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={50}
                duration={1200}
                to="services"
                onClick={scrollToTop}
              >
                Services
              </Link>
            </li>
            <li className="py-1 text-gray-400 text-sm font-semibold">
              <Link
                className="cursor-pointer hover:text-white"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={50}
                duration={800}
                to="contact"
                onClick={scrollToTop}
              >
                Contact
              </Link>
            </li>
          </ul>

          <div className="flex flex-col w-full text-gray-500">
            <div className="uppercase font-medium text-white tracking-wide">
              Follow Us
            </div>
            <div className="flex mt-2">
              <Link
                activeClass="active"
                target="_blank"
                to=""
                className="flex items-center text-gray-400 hover:text-white mr-6"
              >
                <AiFillFacebook className="w-5 h-5" />
              </Link>
              <Link
                activeClass="active"
                target="_blank"
                to=""
                className="flex items-center hover:text-white mr-6"
              >
                <AiFillTwitterSquare className="w-5 h-5" />
              </Link>
              <Link
                activeClass="active"
                target="_blank"
                to=""
                className="flex items-center hover:text-white"
              >
                <AiFillInstagram className="w-5 h-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 container mx-auto mt-4 pt-6 text-gray-400 border-t border-gray-400 flex flex-col md:flex-row justify-center items-center">
        <div>
          Powered By{" "}
          <a
            className="text-white"
            target="_blank"
            href="www.digitaltechflux.com"
          >
            Digital Tech Flux
          </a>{" "}
          © {year}.
        </div>
      </div>
    </footer>
  );
}
