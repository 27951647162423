import React, { useState } from "react";
import {
  Link,
} from "react-scroll";
import "../App.css";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
//import img from "../Img/Image.png"
import img from "../Img/NuovaGen.svg";

export default function Header() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggleClick = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  //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

  return (
    <nav
      className="bg-white py-2 md:py-4 z-10 w-full absolute md:static"
      id="home"
    >
      <div className="container px-4 mx-auto md:flex md:items-center">
        <div className="flex justify-between items-center">
          <Link
            activeClass="active"
            to="home"
            className="flex items-center font-bold cursor-pointer  text-xl text-teal-600"
          >
            Nu
            <img
              src={img}
              loading="lazy"
              alt="description of nuovagen"
              className="w-[40px] h-[40px] bg-[#f4f4f4] mx-1 rounded-full "
            />
            vaGen
          </Link>
          <button
            onClick={handleToggleClick}
            className="px-3 py-1 rounded transition-all
               duration-500  text-gray-600 opacity-70 hover:opacity-100 md:hidden"
          >
            {isCollapsed ? (
              <AiOutlineMenu size={"30px"} />
            ) : (
              <RxCross2 size={"30px"} />
            )}
          </button>
        </div>

        <div
          className={`${
            isCollapsed ? "hidden" : "flex transition ease-out duration-200"
          }
            md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0`}
        >
          <Link
            onClick={handleToggleClick}
            activeClass="active"
            to="home"
            className="p-2 lg:px-4 md:mx-2 text-white cursor-pointer rounded bg-teal-600"
          >
            Home
          </Link>

          <Link
            onClick={handleToggleClick}
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="relative p-2 cursor-pointer lg:px-4 cursor-pointer  btn4 overflow-hidden md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300"
          >
            <span className="absolute inset-x-0 h-[2.5px] bottom-0 bg-black"></span>
            About
          </Link>

          <Link
            onClick={handleToggleClick}
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={50}
            duration={800}
            className="relative p-2 lg:px-4 btn4 overflow-hidden md:mx-2 cursor-pointer  text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300"
          >
            <span className="absolute inset-x-0 h-[2.5px] bottom-0 bg-black"></span>
            Services
          </Link>
          <Link
            onClick={handleToggleClick}
            activeClass="active"
            to="career"
            spy={true}
            smooth={true}
            offset={50}
            duration={1000}
            className="relative p-2 lg:px-4 btn4 overflow-hidden cursor-pointer  md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300"
          >
            <span className="absolute inset-x-0 h-[2.5px] bottom-0 bg-black"></span>
            Careers
          </Link>
          <Link
            onClick={handleToggleClick}
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={50}
            duration={1200}
            className="relative p-2 lg:px-4 btn4 overflow-hidden cursor-pointer  md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300"
          >
            <span className="absolute inset-x-0 h-[2.5px] bottom-0 bg-black"></span>
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
}
